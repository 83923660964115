exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-reset-password-mail-tsx": () => import("./../../../src/pages/account/reset-password-mail.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-mail-tsx" */),
  "component---src-pages-account-reset-password-tsx": () => import("./../../../src/pages/account/reset-password.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-tsx" */),
  "component---src-pages-alexa-auth-tsx": () => import("./../../../src/pages/alexa_auth.tsx" /* webpackChunkName: "component---src-pages-alexa-auth-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-resource-v-3-tsx": () => import("./../../../src/pages/app/resource/v3.tsx" /* webpackChunkName: "component---src-pages-app-resource-v-3-tsx" */),
  "component---src-pages-support-getstarted-tsx": () => import("./../../../src/pages/support/getstarted.tsx" /* webpackChunkName: "component---src-pages-support-getstarted-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-screens-ga-auth-index-page-ga-auth-tsx": () => import("./../../../src/screens/ga-auth/index.page-ga_auth.tsx" /* webpackChunkName: "component---src-screens-ga-auth-index-page-ga-auth-tsx" */),
  "component---src-screens-homepage-index-page-homepage-tsx": () => import("./../../../src/screens/homepage/index.page-homepage.tsx" /* webpackChunkName: "component---src-screens-homepage-index-page-homepage-tsx" */),
  "component---src-screens-products-spectrum-strip-index-page-products-spectrum-strip-tsx": () => import("./../../../src/screens/products/spectrumStrip/index.page-products.spectrum-strip.tsx" /* webpackChunkName: "component---src-screens-products-spectrum-strip-index-page-products-spectrum-strip-tsx" */),
  "component---src-screens-server-test-tsx": () => import("./../../../src/screens/serverTest.tsx" /* webpackChunkName: "component---src-screens-server-test-tsx" */),
  "component---src-templates-general-blog-post-tsx": () => import("./../../../src/templates/generalBlogPost.tsx" /* webpackChunkName: "component---src-templates-general-blog-post-tsx" */)
}

